import {Drawer, Button} from "antd";
import {Translation} from 'react-i18next'
import {CloseOutlined, PictureOutlined} from "@ant-design/icons";
import {connect} from 'react-redux'
import {imageResize} from '../../utils'
import '../desktop/Components/WallpaperList.css'
import {useEffect} from "react";
import {getTheme} from "../../api";


function Setting(props){
    useEffect(()=>{
        // wallpaper list padding
        // const id = props.wallpaper.theme
        // getTheme(id).then(data=>{
        //     const wallpaper = {...props.wallpaper}
        //     wallpaper.wallpapers = data.wallpapers
        //     wallpaper.updateTime = Date.now()
        //     if(!wallpaper.wallpaper || updateWallpaper){
        //         wallpaper.wallpaper = data.wallpapers[0]
        //     }
        //     props.changeWallpaper(wallpaper)
        // })
    },[])

    const handleClose = ()=>{
            props.onClose()
    }

    const handleChangeWallpaper = (wallpaper)=>{
        return ()=>{
            props.changeWallpaper(wallpaper)
        }
    }

    const handleGetMore = () => {
        window.open('https://www.picknewtab.com','_blank')
    }

    return (
        <Translation>
            {t=> (
                props.visible?
                    <Drawer id='wallpaper-list' placement='left' visible={true} onClose={handleClose} closable={false}>
                        <CloseOutlined className='close-btn' onClick={handleClose} />
                        <ul>
                            {
                                props.wallpaper.wallpapers.map(wallpaper=>{
                                    return <li key={wallpaper}>
                                        <img src={wallpaper+imageResize(350,180)} alt="img"/>
                                        <button className="btn" onClick={handleChangeWallpaper(wallpaper)}>{t('wallpaper.use')}</button>
                                    </li>
                                })
                            }
                            <li className='more-btn' onClick={handleGetMore}>
                                <PictureOutlined />
                                {t('wallpaper.getMoreWallpaper')}
                            </li>
                        </ul>
                    </Drawer>
                    :null
            )}
        </Translation>
    )
}

export default connect(
    state=>({
        visible:state.wallpaper.visible.list,
        wallpaper:state.wallpaper
    }),
    {
        onClose:()=>({type:'changeWallpaperList',data:false}),
        changeWallpaper:(data)=>({type:'changeWallpaper',data})
    }
)(Setting)