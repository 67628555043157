import React from 'react'
import './index.css'

export function Slider(props){
    return (
        <input type="range"
               className={props.className||''}
               min={props.min}
               max={props.max}
               step={props.step}
               defaultValue={props.defaultValue}
               value={props.value}
               onChange={props.onChange}/>
    )
}