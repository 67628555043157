import {useMemo, useState} from 'react'
import {Select, Switch, Tooltip, Tag, Space, Spin, Modal, message, Radio, Button} from 'antd'
import { DownOutlined, LoginOutlined, UserOutlined, CloudDownloadOutlined, CloudUploadOutlined, LoadingOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {Translation,useTranslation} from 'react-i18next'
import qs from 'qs'

const {Option} = Select

/**
 * 计算合并后并返回本地与云端导航书签
 */
function calcNavigation(cloud,local){
    const obj = {},
        arr = [],
        navigation = [...cloud.list,...local.list]
    navigation.forEach(item=>{
        if(!obj[item._id]){
            obj[item._id] = 1
            arr.push(item)
        }
    })
    return {...cloud,list:arr}
}

function System(props){
    const [activeVisible,setActiveVisible] = useState(true),
        [activeOther,setActiveOther] = useState(false),
        setting = props.setting,
        {i18n} = useTranslation()

    const visible = [
        {key:'bookmark',value:setting.visible.bookmark,t:'word.bookmark'},
        {key:'wallpaperMask',value:setting.visible.wallpaperMask,t:'setting.visibleWallpaperMask'},
    ]

    /**
     * 计算意见反馈链接
     */
    const feedbackUrl = useMemo(()=>{
        const website = 'https://www.picknewtab.com'
        return `${website}/help/feedback?${qs.stringify(props.system)}`
    },[])

    /**
     * 处理显示/隐藏数据修改
     */
    const handleChangeVisible = (name)=>{
        return (val)=>{
            const setting = props.setting,
                visible = setting.visible
            visible[name] = val
            props.changeSetting({...setting,visible})
        }
    }

    return (
        <Translation>
            {t=> (
                <>
                    <div id='setting-system'>
                        <div className={`card visible ${activeVisible?'':'mini'}`}>
                            <header onClick={()=>{setActiveVisible(!activeVisible)}}>
                                {t('word.visible')}/{t('word.hidden')} <DownOutlined />
                            </header>
                            {
                                activeVisible?
                                <main>
                                    {(() => {
                                        return visible.map((item) => {
                                            return (
                                                <div className='switch' key={item.key}>
                                                    <Switch checked={item.value}
                                                            onChange={handleChangeVisible(item.key)}/>
                                                    {t(item.t)}
                                                </div>
                                            )
                                        })
                                    })()}
                                </main>:null
                            }
                        </div>

                        <div className={`card other ${activeOther?'':'mini'}`}>
                            <header onClick={()=>{setActiveOther(!activeOther)}}>
                                {t('word.other')}<DownOutlined />
                            </header>
                            {
                                activeOther?
                                <main>
                                    <div className='select'>
                                        <span>{t('word.language')}</span>
                                        <Select value={setting.language}
                                                onChange={(val) => {
                                                    i18n.changeLanguage(val).then(()=>{
                                                        props.changeSetting({...setting, language: val})
                                                    })
                                                }}>
                                            <Option value='zh'>
                                                中文
                                            </Option>
                                            <Option value='en'>
                                                English
                                            </Option>
                                        </Select>
                                    </div>
                                    <Space style={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                                        <Button href={feedbackUrl} target='_blank'>
                                            {t('word.feedback')}
                                        </Button>
                                        {
                                            window.chrome.runtime.id !== "browser"?
                                            <Button target='_blank'
                                                    href={`https://microsoftedge.microsoft.com/addons/detail/${window.chrome.runtime.id}`}>
                                                {t('setting.giveStar')}
                                            </Button>:null
                                        }
                                    </Space>
                                </main>:null
                            }
                        </div>
                    </div>

                </>
            )}
        </Translation>
    )
}

export default connect(
    (state)=>({
        state:state,
        navigation:state.navigation,
        search:state.search,
        wallpaper:state.wallpaper,
        user:state.user,
        setting:state.setting,
        system:state.system
    }),
    {
        logout:()=>({type:'logout'}),
        changeSearch:data =>({type:'changeSearch',data}),
        changeUser:data =>({type:'changeUser',data}),
        changeSetting: data => ({type: 'changeSetting', data}),
        changeWallpaper: data => ({type: 'changeWallpaperStorage', data}),
        changeNavigation: data => ({type: 'changeNavigation', data}),
        resetSearch:()=>({type:'reset'}),
        resetNavigation:()=>({type:'resetNavigation'}),
        resetWallpaper:()=>({type:'resetWallpaper'}),
        resetSetting:()=>({type:'resetSetting'}),
    }
)(System)