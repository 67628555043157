import {useEffect, useState} from 'react'
import {getLocalStorage, setLocalStorage} from "../../../utils";
import {getWeather} from "../../../api";
import moment from '../../../plugins/moment'
import {IframeClick} from "../../../components";
import {Translation} from "react-i18next";

/**
 * 实时天气
 */
export default function Weather() {
    const [weather,setWeather] = useState(getLocalStorage('weather-data')),
        [full,setFull] = useState(false);


    useEffect(()=>{
        if(!weather || weather.crtime<Date.now()-1000*60*60){
            setWeather(null)
            setFull(false)
            getWeather().then(data=>{
                if(data){
                    setLocalStorage('weather-data',{...data,crtime:Date.now()})
                    setWeather(data)
                }
            })
        }
    },[])


    const handleFull = (e)=>{
        e.stopPropagation()
        setFull( true )
    }

    const handleClose = ()=>{
        setFull(false)
    }


    return <Translation>
        {
            t=>( weather?
                    <div id='weather' onClick={handleFull}>
                        <img src={getWeatherIcon(weather.realtime.info)} alt={weather.realtime.info}/>{weather.realtime.temperature}℃
                        {
                            full?
                                <Max onClose={handleClose} {...weather}/>:null
                        }
                    </div>
                    :null
            )
        }
    </Translation>
}

/**
 * 最近天气
 */
function Max(props){
    useEffect(()=>{
        window.addEventListener('click',handleClose)
        return ()=>{
            window.addEventListener('click',handleClose)
        }
    },[])

    const handleClose = (e)=>{
        e?.stopPropagation()
        props.onClose()
        window.addEventListener('click',handleClose)
    }

    return (
    <div id='weather-full' onClick={handleClose}>
            <header>
                <div>
                    <div>{ props.realtime.temperature }°C</div>
                    <div>{ props.realtime.info }</div>
                </div>
                <div>{ props.city }</div>
                <div>{ moment().format("MM-DD dddd") }</div>
            </header>
            <hr />
            <ul>
                {
                    props.future.slice(0,5).map(((item,i)=>{
                        return (
                            <li key={i} >
                                <div>{ moment(item.date).format("MM-DD") }</div>
                                <div>
                                    <img
                                    src={getWeatherIcon(item.text_day)}
                                    alt={moment(item.date).format('DD')+'日'}
                                    />
                                </div>
                                <div>{ item.low } ~ { item.high }°C</div>
                                <div>{ item.text_day }</div>
                                <div>{ item.wd_day }</div>
                            </li>
                        )
                    }))
                }
            </ul>
            <IframeClick>{()=>{handleClose()}}</IframeClick>
        </div>
    )
}

const getWeatherIcon = (info)=>{
    const ICON_BASE_URL = "https://image.picknewtab.com/weather/w/";
    const ICONS = [
        {
            wid: "00",
            weather: "晴",
        },
        {
            wid: "01",
            weather: "多云",
        },
        {
            wid: "02",
            weather: "阴",
        },
        {
            wid: "03",
            weather: "阵雨",
        },
        {
            wid: "04",
            weather: "雷阵雨",
        },
        {
            wid: "05",
            weather: "雷阵雨伴有冰雹",
        },
        {
            wid: "06",
            weather: "雨夹雪",
        },
        {
            wid: "07",
            weather: "小雨",
        },
        {
            wid: "08",
            weather: "中雨",
        },
        {
            wid: "09",
            weather: "大雨",
        },
        {
            wid: "10",
            weather: "暴雨",
        },
        {
            wid: "11",
            weather: "大暴雨",
        },
        {
            wid: "12",
            weather: "特大暴雨",
        },
        {
            wid: "13",
            weather: "阵雪",
        },
        {
            wid: "14",
            weather: "小雪",
        },
        {
            wid: "15",
            weather: "中雪",
        },
        {
            wid: "16",
            weather: "大雪",
        },
        {
            wid: "17",
            weather: "暴雪",
        },
        {
            wid: "18",
            weather: "雾",
        },
        {
            // wid: "19",
            wid: "05",
            weather: "冻雨", // 雨夹雪
        },
        {
            wid: "20",
            weather: "沙尘暴",
        },
        {
            // wid: "21",
            wid: "08",
            weather: "小到中雨", // 中雨
        },
        {
            // wid: "22",
            wid: "09",
            weather: "中到大雨", // 大雨
        },
        {
            // wid: "23",
            wid: "10",
            weather: "大到暴雨", // 暴雨
        },
        {
            // wid: "24",
            wid: "11",
            weather: "暴雨到大暴雨", // 大暴雨
        },
        {
            // wid: "25",
            wid: "12",
            weather: "大暴雨到特大暴雨", // 特大暴雨
        },
        {
            // wid: "26",
            wid: "15",
            weather: "小到中雪", // 中雪
        },
        {
            // wid: "27",
            wid: "16",
            weather: "中到大雪", // 大雪
        },
        {
            // wid: "28",
            wid: "17",
            weather: "大到暴雪", // 暴雪
        },
        {
            wid: "29",
            weather: "浮尘",
        },
        {
            wid: "30",
            weather: "扬沙",
        },
        {
            wid: "31",
            weather: "强沙尘暴",
        },
        {
            wid: "53",
            weather: "霾",
        },
    ];
    let index = ICONS.findIndex((t) => t.weather === info);
    if (index === -1) index = 0;
    return ICON_BASE_URL + ICONS[index].wid + ".svg";
}