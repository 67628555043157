import {getLocalStorage,setLocalStorage} from "../../utils";
import baiduLogo from "../../assets/img/search_icon_baidu.png";
import bingLogo from "../../assets/img/search_icon_bing.png";
import googleLogo from "../../assets/img/search_icon_google.png";
import _360Logo from "../../assets/img/search_icon_360.png";
import sougouLogo from "../../assets/img/search_icon_sougou.png";
import yandexLogo from "../../assets/img/search_icon_yandex.png";

const STORAGE_KEY = 'search-data'

export const initState = {
    current: "baidu",
    searchEngineList: [
        {id: "baidu", name: "baidu", img: baiduLogo,href:"https://www.baidu.com/s?ie={encoding}&wd={query}",
            info:'全球领先的中文搜索引擎、致力于让网民更便捷地获取信息，找到所求。百度超过千亿的中文网页数据库，可以瞬间找到相关的搜索结果。'},
        {id: "bing", name: "bing", img: bingLogo,href:"https://cn.bing.com/search?q={query}",
            info:'必应可帮助你将理论付诸实践,使得搜索更加方便快捷,从而达到事半功倍的效果。'},
        {id: "google", name: "google", img: googleLogo,href:"https://www.google.com/search?q={query}&ie={encoding}",
            info:'世界上最大的搜索引擎之一'},
        {id: "360", name: "360", img: _360Logo,href:"https://www.so.com/s?q={query}",
            info:"360搜索是安全、精准、可信赖的新一代搜索引擎,依托于360母品牌的安全优势,全面拦截各类钓鱼欺诈等恶意网站,提供更放心的搜索服务。"},
        // new
        {id: "sougou", name: "sougou", img: sougouLogo,href:"https://www.sogou.com/sogou?query={query}",
            info:"搜狗搜索是全球第三代互动式搜索引擎,支持微信公众号和文章搜索、知乎搜索、英文搜索及翻译等,通过自主研发的人工智能算法为用户提供专业、精准、便捷的搜索服务。"},
        // new
        {id: "yandex", name: "yandex", img: yandexLogo,href:"https://yandex.com/search/?text={query}",
            info:"Yandex是俄罗斯重要网络服务门户之一。"},
    ],
    searchEngineUse: ["baidu", "bing", "google", "360"],
    setting1: {rounded: 50, width: 45, height: 50, bgOpacity: 1, border: 1, top: 100, backgroundColor:'#fff',
        borderColor:'#fff'},
    setting2: {rounded: 10, width: 50, height: 50, bgOpacity: 0, border: 2, top: 100, backgroundColor:'#fff',
        borderColor:'#fff',color:'#fff'},
    setting3: {rounded: 10, width: 50, height: 50, bgOpacity: 1, border: 4, top: 100, backgroundColor:'#fdf9c3',
        color: '#6b7280',borderColor:'#19766e' },
    theme: "theme1",
    visibleLogo:true
}

function getStorage(){
    const storage = getLocalStorage(STORAGE_KEY)
    return storage || initState
}


export default (preState=getStorage(),action)=>{
    let theme = preState.theme,
        themeKey =  `setting${theme.slice(5)}`,
        storage = {...preState}
    switch (action.type){
        case 'changeSearch':
            setLocalStorage(STORAGE_KEY,action.data)
            if(action.data.current!==preState.current){
                return {...action.data,changing:true}
            }
            return action.data
        case 'changeSearchSetting':
            storage[themeKey] = {...storage[themeKey],[action.data.key]:action.data.value}
            setLocalStorage(STORAGE_KEY,storage)
            return storage
        case 'incrementSearch':
            storage.current = action.data.id
            storage.searchEngineUse = [...storage.searchEngineUse,action.data.id]
            storage.searchEngineList = [...storage.searchEngineList,action.data]
            setLocalStorage(STORAGE_KEY,storage)
            return storage
        case 'resetSearchSetting':
            storage =  {
                ...preState,
                [themeKey]:initState[themeKey],
                current:initState["current"],
                searchEngineUse:initState["searchEngineUse"],
                visibleLogo:true
            }
            setLocalStorage(STORAGE_KEY,storage)
            return storage
        case 'resetSearch':
            setLocalStorage(STORAGE_KEY,initState)
            return initState
        default:
            return preState
    }
}