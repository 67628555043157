import {connect} from 'react-redux'
import {Button, Form, Input, message, Space, Tooltip} from "antd";
import {useMemo, useRef, useState} from "react";
import {PlusOutlined, EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Translation} from 'react-i18next'
import {nanoid} from 'nanoid'
import {searchEngineForage} from "../../plugins/localforage";
import {uploadFile} from "../../api";

const {Item,useForm} = Form

function Custom(props){
    const [form] = useForm(),
        [imageFile,setImageFile] = useState(null),
        [urlValidateStatus,setUrlValidateStatus] = useState('success'),
        [urlHelp,setUrlHelp] = useState(''),
        // [showTip,setShowTip] = useState(false),
        uploadRef = useRef()

    const imageUrl = useMemo(()=>{
        return imageFile?URL.createObjectURL(imageFile):null
    },[imageFile])

    /**
     * 添加提交处理
     */
    const handleSubmit = (e)=>{
        const engineObj ={
            id:nanoid(),
            name:form.getFieldValue('name'),
            href:form.getFieldValue('url'),
            isCustom:true,
        }

        if(!/^https?:\/\//){
            engineObj.href = 'http://' +engineObj.href
        }

        if(imageFile){
            uploadFile(imageFile).then(res=>{
                if(res?.url){
                    engineObj.img = res.url
                    props.incrementSearch(engineObj)
                    handleReset()
                }else{
                    searchEngineForage.setItem(engineObj.id,imageFile).then(()=>{
                        engineObj.img = engineObj.id
                        engineObj.isLocalIcon = true
                        props.incrementSearch(engineObj)
                        handleReset()
                    })
                }
            })
        }else{
            props.incrementSearch(engineObj)
            handleReset()
        }
    }

    const handleUploadChange = (e)=>{
        const file = e.target.files[0]
        setImageFile(file)
    }

    const handleReset = ()=>{
        form.setFieldsValue({name:'',url:''})
        setImageFile(null)
    }

    /**
     * 校验URL链接
     */
    const handleUrlValidator = ({})=>({
        async validator(_,value){
            let help='',
                status ='success';
            if(!value){
                help = 'tip.linkRequired'
                status = 'error'
            }else if(!/\{query}/.test(value)){
                help = 'tip.linkNotQueryWord'
                status = 'warning'
            }
            setUrlHelp(help)
            setUrlValidateStatus(status)
            if(status==='error'){
                throw new Error(help)
            }
        }
    })

    const handleTest = ()=>{
        if(!form.getFieldValue('url')){
            message.info('搜索引擎链接为空。')
        }else{
            let url = form.getFieldValue('url')
            if(!/^https?:\/\//){
                url = 'http://' +url
            }
            window.open(url.replace(/\{query\}/,'picknewtab'),'_blank')
        }
    }

    return (
        <Translation>
            {t=>(
                <Form layout='vertical' form={form} onFinish={handleSubmit}>
                    <Item label={t('word.name')} name='name' rules={[{required:true,message:t('tip.nameRequired')}]}>
                        <Input placeholder='baidu' />
                    </Item>
                    <Item label={
                            <>
                                {t('word.link')}
                                {t('tip.linkUseHelp')}
                                <Tooltip title={t('word.example')+"\nhttps://www.baidu.com/s?kw={query}"}>
                                    <QuestionCircleOutlined style={{cursor:'pointer'}} />
                                </Tooltip>
                            </>
                          }
                          name='url'
                          required={true}
                          rules={[handleUrlValidator]}
                          validateStatus={urlValidateStatus}
                          help={t(urlHelp)}
                    >
                        <Input placeholder='https://www.baidu.com/s?kw={query}' />
                    </Item>
                    <Item label={t('word.icon')} >
                        <div className='upload-item'>
                            <div className='upload-button icon'
                                 onClick={()=>{
                                     uploadRef.current.click()
                                 }}>
                                {
                                    imageUrl?
                                        <>
                                            <img src={imageUrl} />
                                            <EditOutlined className='re-upload-button' />
                                        </>:
                                        <>
                                            <PlusOutlined />
                                            <div>{t('word.upload')}</div>
                                        </>
                                }
                            </div>
                            <input style={{display:'none'}} type="file" ref={uploadRef} onChange={handleUploadChange}/>
                        </div>
                    </Item>
                    <Item>
                        <Space>
                            <Button type='primary' htmlType='submit'>{t('word.add')}</Button>
                            <Button type='link' onClick={handleTest}>{t('word.test')}</Button>
                        </Space>
                    </Item>
                </Form>
            )}
        </Translation>
    )
}

export default connect(
    (state) =>({search:state.search}),
    {
        incrementSearch:(data)=>({type:'incrementSearch',data})
    }
)(Custom)