import localForage from "localforage"

const COMMON_CONFIG = {
    // name: 'import.meta.env.npm_package_name!',
    name: 'insearch',
    driver: localForage.INDEXEDDB
}

export const wallpaperForage = localForage.createInstance({
    ...COMMON_CONFIG,
    storeName: "wallpaper"
})

export const searchEngineForage = localForage.createInstance({
    ...COMMON_CONFIG,
    storeName: "search-engine"
})

export const navigationForage = localForage.createInstance({
    ...COMMON_CONFIG,
    storeName: "navigation-icons",
});

export const searchIconGet = async (key) =>{
    try{
        if(!key) return null
        const data =  await searchEngineForage.getItem(key)
        if(data==null){
            searchEngineForage.removeItem(key).then(null)
        }
        return data
    }catch(e){
        return null
    }
}

export const navigationIconGet = async (key)=>{
    try{
        if(!key) return null
        const data =  await navigationForage.getItem(key)
        if(data==null){
            navigationForage.removeItem(key)
        }
        return data
    }catch(e){
        return null
    }
}

export const navigationIconReset = ()=>{
    navigationForage.keys().then((keys)=>{
        keys.forEach(key=>{
            navigationForage.removeItem(key).then(null)
        })
    })
}
