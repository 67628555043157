import React from 'react'

export class IframeClick extends React.Component {
    componentDidMount() {
        const iframe = document.querySelector('Iframe.wallpaper')
        if(!iframe) return
        window.addEventListener('message',this.handleMessage)
    }

    componentWillUnmount() {
        window.removeEventListener('message',this.handleMessage)
    }

    handleMessage = (e) => {
        if(e.data?.source==='wallpaper' && e.data?.payload?.event==='click') {
            const data = {...e.data.payload}
            if(typeof this.props.children==='function'){
                return this.props.children(data)
            }else if(typeof this.props.render==='function') {
                return this.props.render(data)
            }
            window.removeEventListener('message',this.handleMessage)
        }
    }

    render(){
        return null
    }

}

