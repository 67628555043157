import {connect} from "react-redux";
import Background from "./Background";
import WallpaperList from "./List";
import './index.css'
import {useEffect} from "react";
import {getThemeWithWallpaper} from "../../api";
import {parseString} from '../../utils'

function Wallpaper(props){
    useEffect(()=>{
        getWallpapers()
    },[])

    const getWallpapers = ()=>{
        const queryString = window.location.search.substring(1),
            queryObj =  parseString(queryString),
            wallpaperId = queryObj.id || 333
        getThemeWithWallpaper(wallpaperId).then(data=>{
            const wallpaper = {...props.wallpaper}
            wallpaper.wallpapers = data.wallpapers.map(item=>'http:'+item.cover)
            wallpaper.wallpaper = 'http:'+data.wallpaper.cover
            wallpaper.theme = data.theme
            props.changeWallpaper(wallpaper)
        })

    }

    return  <>
        <div id='wallpaper' className={`wallpaper`}>
            {
                // isMac?
                    <Background />
                    // <Iframe />
            }
            { props.visibleMask || props.searchFocus?
                <div className={props.searchFocus?'mask focus':'mask'} />:null
            }
        </div>
        {
            props.visibleList?
                <WallpaperList />
                :null
        }
    </>
}

export default connect(
    (state)=>({
        wallpaper:state.wallpaper,
        searchFocus:state.wallpaper.visible.mask,
        visibleMask:state.setting.visible.wallpaperMask,
        visibleList:state.wallpaper.visible.list
    }),
    {
        changeWallpaper:(data)=>({type:'changeWallpaperStorage',data})
    }
)(Wallpaper)

