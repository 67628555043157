import {connect} from 'react-redux'
import "./HeaderWrapper.css"
import {useEffect, useState} from "react";
import {getBrowser} from '../utils'

const store_url_list = [
    "https://microsoftedge.microsoft.com/addons/detail/addmemkbaodaffnelkcdeidgjijobonb",
    "https://chrome.google.com/webstore/detail/cgfoblfgckflokkpofofglfidhfmngjo"
]

function HeaderWrapper(props){
    const [isChrome,setIsChrome] = useState(false)
    useEffect(()=>{
        const browser = getBrowser(window.navigator.userAgent)
        if(/chrome/.test(browser)){
                setIsChrome(true)
        }
    },[])
    return (
        <div className="headerWrapper">
            <div >
                您正在使用网页预览版，请
                <a target="_blank" href={store_url_list[isChrome?1:0]}>进入edge商店</a>安装插件版（打开商店可能需要1-2分钟）
            </div>
        </div>
    )
}

export default connect(state=>({ state }),
    {
    }
)(HeaderWrapper)