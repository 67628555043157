import {getLocalStorage, setLocalStorage} from "../../utils";
import {GET_DEFAULT_NAVIGATION} from "../constants";

const STORAGE_KEY = 'navigation-data'

export const INIT_STATE = {
    list:null,
    localFiles:null,
    opacity:1,
    radius:10,
    size:100, // %
    spaceX:40,
    spaceY:40,
    visibleAddButton:true,
    width:931,
    height:350, // new
    rows:2,  // new
    columns:7, // new
    top:277 // new
}

function getStorage(){
    let storage = getLocalStorage(STORAGE_KEY)
    storage =  storage || INIT_STATE
    storage.rows===undefined && (storage.rows=INIT_STATE.rows)
    storage.columns===undefined && (storage.columns=INIT_STATE.columns)
    return storage
}

function  setStorage(storage){
    storage.adding = undefined
    setLocalStorage(STORAGE_KEY,storage)
}


export default (preState=getStorage(),action)=>{
    let storage = {...preState}
    switch (action.type){
        case 'changeNavigation':
            setStorage(action.data)
            return action.data
        case GET_DEFAULT_NAVIGATION:
            storage.list = action.data
            setStorage(storage)
            return storage
        case 'resetNavigationSetting':
            storage = {...INIT_STATE,list:storage.list}
            setStorage(storage)
            return storage
        case 'incrementNavigation':
            storage.list = [...storage.list,action.data]
            setStorage(storage)
            storage.adding = true
            return storage
        case 'changeNavigationPage':
            storage.page = action.data
            setStorage(storage)
            return storage
        case 'resetNavigation':
            setStorage(INIT_STATE)
            return INIT_STATE
        default:
            return storage
    }
}