import {getLocalStorage, setLocalStorage} from "../../utils";
import {nanoid} from "nanoid";

const STORAGE_KEY = 'user-data'
// http://dev.picknewtab.com/login/cb?type=extension
// &#access_token=1F7A6A87A3CEB89CFDA18F2636DBB042
// &expires_in=7776000

export const initState = {
    id:undefined,
    qqLoginUri:'https://www.jianyueku.com/index/login/qqlogin?loginToken=',
    autoBackup:true,
    backupCycle:'0',  // 自动备份周期 ["每次", "每天", "每周"]
    currentDataTime:undefined,  // 当前数据的时间节点（用来标记本地数据与云数据的差异）
    favThemes:null,
    favWallpapers:null,
    nickname:undefined,
}

function getStorage(){
    let storage = getLocalStorage(STORAGE_KEY)
    return storage?{...initState,...storage}:initState
}

function setStorage(storage){
    setLocalStorage('user-data',{...storage,qqLoginUri:undefined})
}


export default (preState=getStorage(),action)=>{
    const storage = {...preState}
    switch (action.type){
        case 'logout':
            const data = {...initState}
            if(action.data===true){
                data['currentDataTime'] = storage.currentDataTime
            }
            setStorage(action.data)
            return data
        case 'changeUser':
            setStorage(action.data)
            return action.data
        case 'toggleFavTheme':
            if(!storage.favThemes){
                storage.favThemes = [action.data]
            }else if(storage.favThemes.indexOf(action.data)===-1){
                storage.favThemes = [action.data,...storage.favThemes]
            }else{
                storage.favThemes = storage.favThemes.filter(theme=>theme!==action.data)
            }
            setStorage(storage)
            return storage
        case 'toggleFavWallpaper':
            if(!storage.favWallpapers){
                storage.favWallpapers = [action.data]
            }else if(storage.favWallpapers.indexOf(action.data)===-1){
                storage.favWallpapers = [action.data,...storage.favWallpapers]
            }else{
                storage.favWallpapers = storage.favWallpapers.filter(wallpaper=>wallpaper!==action.data)
            }
            setStorage(storage)
            return storage
        default:
            return preState
    }
}