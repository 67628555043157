import React from 'react'
import {Drawer,Tabs} from "antd";
import {Translation} from 'react-i18next'
import {CloseOutlined} from "@ant-design/icons";
import {connect} from 'react-redux'
import './setting.css'
import Search from "./search";
import System from "./system";
import Navigation from "./navigation";


function Setting(props){
    const {TabPane} = Tabs

    const handleClose = ()=>{
            props.onClose(false)
    }

    return (
        <Translation>
            {t=> (
                props.visible?
                    <Drawer width={350} mask={true} zIndex={1002} placement='right' id='setting' visible={true}
                            onClose={handleClose} closable={false}>
                        <CloseOutlined className='close-btn' onClick={handleClose} />
                        <Tabs defaultActiveKey="system">
                            <TabPane tab={t('word.search')} key="search">
                                <Search/>
                            </TabPane>
                            <TabPane tab={t('word.bookmark')} key="navigation">
                                <Navigation/>
                            </TabPane>
                            <TabPane tab={t('word.setting')} key="system">
                                <System/>
                            </TabPane>
                        </Tabs>
                    </Drawer>
                    :null
            )}
        </Translation>
    )
}

export default connect(
    state=>({visible:state.common.visibleSetting}),
    {
        onClose:data=>({type:'changeSettingDrawer',data})
    }
)(Setting)