export const getBrowser = (userAgent) =>{
    userAgent = userAgent ?? window.navigator.userAgent
    userAgent = userAgent.toLowerCase()
    if (/mozilla\/.*macintosh.*applewebkit\/.*chrome\/.*safari\/.*edg.*/.test(userAgent)) {
        return 'Edge Mac'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*safari\/.*edg.*/.test(userAgent)) {
        return 'Edge Windows'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*safari\/.*metasr\/.*/.test(userAgent)) {
        return 'Sougou Windows'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*safari\/.*qqbrowser\/.*/.test(userAgent)) {
        return 'QQbrowser Windows'
    } else if (/mozilla\/.*macintosh.*applewebkit\/.*chrome\/.*safari\/.*/.test(userAgent)) {
        return 'Chrome Mac'
    } else if (/mozilla\/.*applewebkit\/.*crios\/.*mobile\/.*safari\/.*/.test(userAgent)) {
        return 'Chrome IOS'
    } else if (/mozilla\/.*applewebkit\/.*version\/.*mobile\/.*safari\/.*/.test(userAgent)) {
        return 'Safari IOS'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*quark\/.*mobile.*safari\/.*/.test(userAgent)) {
        return 'Quark Android'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*ucbrowser\/.*mobile.*safari\/.*/.test(userAgent)) {
        return 'UCBrowser Android'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*mqqbrowser\/.*mobile.*safari\/.*/.test(userAgent)) {
        return 'QQBrowser Android'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*mobile.*safari\/.*baiduboxapp\/.*/.test(userAgent)) {
        return 'Baidu Android'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*mobile.*safari\/.*/.test(userAgent)) {
        return 'Chrome Android'
    } else if (/mozilla\/.*applewebkit\/.*version\/.*mobile.*safari\/.*/.test(userAgent)) {
        return 'Webkit Android'
    } else if (/mozilla\/.*applewebkit\/.*chrome\/.*safari\/.*/.test(userAgent)) {
        return 'Chrome Windows'
    } else if (/mozilla\/.*applewebkit\/.*safari\/.*/.test(userAgent)) {
        return 'Safari Mac'
    } else if (/opera\/.*presto\/.*version\/.*/.test(userAgent)) {
        return 'Opera Window'
    } else if (/mozilla\/.*applewebkit\/.*gecko\/.*/.test(userAgent)) {
        return 'Firefox Mac'
    } else if (/mozilla\/.*gecko\/.*firefox\/.*/.test(userAgent)) {
        return 'Firefox Android'
    } else if (/mozilla\/.*gecko$/.test(userAgent)) {
        return 'IE Windows'
    } else if (/360/.test(userAgent)) {
        return '360 Windows'
    }
    return 'None'
}

export const getIsMac = ()=>{
    if(/mac/gi.test(window.navigator.userAgent)){
        return true
    }
    return false
}