import {connect} from 'react-redux'
import WindmillImage from '../../../assets/img/windmill.png'

function Windmill(props){
    /**
     * 壁纸切换
     */
    const handleToggleWallpaper = ()=>{
        const storage = {...props.wallpaper}
        let index = 0
        if(props.wallpaper.isFavWallpaper){
            if(props.user.favWallpapers.length>0){
                index = props.user.favWallpapers.indexOf(props.wallpaper.wallpaper)
                index = index>=props.user.favWallpapers.length-1?0:index+1
                storage.wallpaper = props.user.favWallpapers[index]
            }else{
                storage.wallpaper = storage.wallpapers[index]
                storage.isFavWallpaper = false
            }
        }else{
            index = props.wallpaper.wallpapers.indexOf(props.wallpaper.wallpaper)
            index = index>=props.wallpaper.wallpapers.length-1?0:index+1
            storage.wallpaper = storage.wallpapers[index]
        }
        storage.loading = true
        props.changeWallpaper(storage)
    }

    return props.wallpaper.wallpapers?
        <div id='windmill' className={props.wallpaper.loading?'rotating':''}>
            <img src={WindmillImage} alt="windmill" onClick={handleToggleWallpaper}/>
        </div>:
        null
}

export default connect(
    state=>({
        wallpaper:state.wallpaper,
        user:state.user
    }),
    {
        changeWallpaper:data=>({type:'changeWallpaperStorage',data})
    }
)(Windmill)