import {getLocalStorage, setLocalStorage} from "../../utils";

const STORAGE_KEY = 'wallpaper-data'

export const initState = {
    theme:window.$pick?.type || 17,
    wallpaper:undefined,
    wallpapers:undefined,
    localWallpapers:undefined,
    isFavWallpaper:false,
    removeWallpapers:undefined,
    visible:{
       mask:false,
       list:false
    },
    updateTime:undefined,
    loading:false
}

function getStorage(){
    const storage = getLocalStorage(STORAGE_KEY)
    const newStorage = storage?{...initState,...storage}:initState
    if(storage && (Object.keys(storage).length !== Object.keys(newStorage))){
        setStorage(newStorage)
    }
    return newStorage
}

function calcNotRemoveWallpapers(storage){
    const wallpapers = storage.wallpapers || [],
        localWallpapers = storage.localWallpapers || [],
        removeWallpapers = storage.removeWallpapers || [];
    const ws = [], lws =[], obj={}

    for(let wallpaper of wallpapers){
        if(removeWallpapers.findIndex(item=>item===wallpaper)===-1){
            ws.push(wallpaper)
        }
    }

    for(let wallpaper of localWallpapers){
        if(removeWallpapers.findIndex(item=>item===wallpaper)===-1){
            if(!obj[wallpaper]){
                lws.push(wallpaper)
                obj[wallpaper] = true
            }
        }
    }

    return {...storage,localWallpapers:lws,wallpapers:ws}
}

function setStorage(storage){
    setLocalStorage('wallpaper-data',{...storage,visible:undefined,loading:undefined})
}

export default (preState=getStorage(),action)=>{
    let storage = {...preState}
    switch (action.type){
        case 'changeWallpaper':
            // 更改当前壁纸
            storage.wallpaper = action.data
            setStorage(storage)
            return storage
        case 'changeWallpaperStorage':
            // 更改storage
            for(let key in storage){
                storage[key]  = action.data[key]
            }
            setStorage(storage)
            return calcNotRemoveWallpapers(storage)
        case 'removeWallpaper':
            // 移除壁纸（不喜欢）
            const removeWallpapers = storage.removeWallpapers || []
            const index = removeWallpapers.findIndex(item=>item===action.data)
            if(index===-1){
                removeWallpapers.push(action.data)
            }
            storage.removeWallpapers = removeWallpapers
            setStorage(storage)

            return calcNotRemoveWallpapers(storage)
        case 'increaseLocalWallpaper':
            // 添加本地壁纸(多张)
            const localWallpapers = storage.localWallpapers || []
            const data = action.data
            for(let url of data){
                let flag = false
                for(let oldUrl of localWallpapers) {
                    if (url === oldUrl) {
                        flag = true
                        break
                    }
                }
                if(!flag){
                    localWallpapers.push(url)
                }
            }
            storage.localWallpapers = localWallpapers
            setStorage(storage)
            return storage
        case 'changeTheme':
            // 修改主题
            storage.theme = action.data
            setStorage(storage)
            return storage
        case 'toggleWallpaperMask':
            // 修改壁纸遮罩
            storage.visible.mask = !storage.visible.mask
            return storage
        case 'changeWallpaperList':
            // 修改壁纸列表显示/隐藏
            storage.visible.list = action.data
            return storage
        case 'resetWallpaper':
            // 重置壁纸storage
            setStorage(initState)
            return {...initState,init:true}
        default:
            return calcNotRemoveWallpapers(storage)
    }
}