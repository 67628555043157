import {PlusOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {useEffect, useState} from "react";
import {Tooltip} from 'antd'
import {Translation} from 'react-i18next'
import LoadingIcon from '../../assets/img/loading.gif'
import {IframeClick} from "../../components";
import {handleImgLoad} from "./index";
import searchDefaultIcon from '../../assets/img/search_icon_default.png'

function SearchList(props){
    const [list,setList] = useState([])

    useEffect(()=>{
        window.addEventListener('click', handleClose)
        const list = props.search.searchEngineList.filter(item=>props.search.searchEngineUse.includes(item.id))
        setList(list)
        return ()=>{
            window.removeEventListener('click',handleClose)
        }
    },[])

    const handleClose = ()=>{
        props.onClose()
    }

    const handleToggle = (id)=>{
        return () =>{
            props.onClickLi(id)
        }
    }

    const handleIframeClick = (data) =>{
        if(data) {
            handleClose()
        }
    }

    return <Translation>
        {t=>(
            <ul id='search-list'>
                {
                    list.map(item=>{
                        return (
                            <li className={`${item.id===props.search.current?'active':''}`} key={item.id}
                                onClick={handleToggle(item.id)}>
                                <Tooltip placement='top' title={t(item.name || 'word.custom')}>
                                    <img onLoad={handleImgLoad()}
                                         src={LoadingIcon}
                                         data-src={item.img || searchDefaultIcon}
                                         alt={item.name}/>
                                </Tooltip>
                            </li>
                        )
                    })
                }
                <li onClick={()=>{props.onShowDrawer()}}>
                    <span>
                        <PlusOutlined />
                    </span>
                </li>
                {
                    <IframeClick>{handleIframeClick}</IframeClick>
                }
            </ul>
        )}
    </Translation>
}

export default connect(
    (state)=>({search:state.search}),
)(SearchList)