import './App.css';
import React from 'react'
import Wallpaper from "./pages/wallpaper";
import Search from "./pages/search";
import Desktop from './pages/desktop'
import Navigation from './pages/navigation/index'
import Setting from "./pages/setting";
import Home from "./pages/home";
import HeaderWrapper from "./pages/HeaderWrapper";
import './locales'

function App(){
    return (
        <>
            <HeaderWrapper />
            <div className='body-content'>
                <Home/>
                <Wallpaper/>
                <Search/>
                <Navigation/>
                <Desktop/>
                <Setting />
            </div>
        </>
    );
}

export default App;
