import React, {useEffect, useState} from 'react'
import {DoubleRightOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {getFrontWebsite} from '../../../api'
import {getCookie,setCookie} from "../../../utils";

function MoreTheme(props){
    const [website,setWebsite] = useState(getCookie('website'))

    useEffect(()=>{
        if(!website){
            getWebsite()
        }
    },[])

    const getWebsite = ()=>{
        getFrontWebsite().then(site=>{
            if(site){
                setWebsite(site)
                setCookie('website',site,{expires:60*60*24*7})
            }else{
                setWebsite('https://www.picknewtab.com')
            }
        })
    }

    const handleClick = ()=>{
        // padding
        window.open('https://www.picknewtab.com','_blank')
    }

    return props.visible?
            <div id='more-theme' onClick={handleClick}>MORE THEME<DoubleRightOutlined /></div>
            :null
}

export default connect(
    state=>({
        visible:state.setting.visible.moreTheme
    })
)(MoreTheme)