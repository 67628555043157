import {getLocalStorage, setLocalStorage} from "../../utils";

const STORAGE_KEY = 'setting-data'

export const initState = {
    language:'zh',
    visible:{
        bookmark:false,
        moreTheme:true,
        time:true,
        weather:true,
        wallpaperMask:true
    }
}

function getStorage(){
    const storage = getLocalStorage(STORAGE_KEY)
    return storage || initState
}


export default (preState=getStorage(),action)=>{
    const {type,data}  = action
    switch (type){
        case 'changeSetting':
            setLocalStorage(STORAGE_KEY,data)
            return data
        case 'resetSetting':
            setLocalStorage(STORAGE_KEY,initState)
            return initState
        default:
            return preState
    }
}