import LeftBottom from "./LeftBottom";
import LeftTop from "./RightTop";
import RightBottom from "./RightBottom";
import RightTop from "./LeftTop";
import './desktop.css'

export default function Desktop(){
    return (
        <>
            <LeftTop />
            <LeftBottom />
            <RightTop />
            <RightBottom />
        </>
    )
}