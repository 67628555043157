import {connect} from 'react-redux'
import {useEffect} from "react";
import {backupData} from "../utils";
import {stats} from "../api"

function Home(props){
    useEffect(()=>{
        const isLogin = props.state.user.nickname && props.state.user.token,
            autoBackup = props.state.user.autoBackup,
            backupTime = props.state.user.currentDataTime,
            backupCycle = props.state.user.backupCycle
        let backupHandler = null
        if(isLogin && autoBackup) {
            switch(backupCycle){
                case '0':
                    backupHandler = handleBackup()
                    break
                case '1':
                    if(!backupTime || Date.now() > backupTime+1000*60*60*24) backupHandler = handleBackup()
                    break
                case '2':
                    if(!backupTime || Date.now() > backupTime+1000*60*60*24*7) backupHandler = handleBackup()
                    break
            }
        }
        if(backupHandler && typeof backupHandler === 'function'){
            backupHandler()
        }
        if(props.state.system){
            stats('open',props.state.system)
        }
        return ()=>{
            if(backupHandler && typeof backupHandler==='function'){
                backupHandler()
            }
        }
    },[])

    const handleBackup = ()=>{
        backupData({
            state(){
                return props.state
            },
        })
    }
    return null
}

export default connect(state=>({ state }),
    {
    }
)(Home)