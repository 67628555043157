export function imageTransform(props) {
    let process = '?x-oss-process=image'
    for (const key in props) {
        process += `/${key},${props[key]}`;
    }
    return process
}

export function imageResize(w, h) {
    if (typeof w === 'string') {
        return "!" + w
    } else {
        return imageTransform({ resize: `m_fill,w_${w},h_${h}` })
    }
}