import {Provider} from 'react-redux';
import ReactDOMClient from 'react-dom/client';
import 'antd/dist/antd.css'
import App from './App';
import store from './redux/store'
import './animate.css'
import './index.css';

const app = ReactDOMClient.createRoot(document.querySelector('#root'))
app.render(
    <Provider store={store}>
        <App />
    </Provider>
)
