import React, {useEffect, useRef, useState} from 'react'
import {Drawer, Tabs, Input, message} from 'antd'
import {CloseOutlined, LinkOutlined, LoadingOutlined} from '@ant-design/icons'
import {connect} from 'react-redux'
import {Translation} from 'react-i18next'
import './library.css'
import {getNavigationLibrary, getNavigationLibraryCategories} from "../../api";
import Custom from "./custom";

const {TabPane} = Tabs,
    {Search} = Input;

function NavigationLibrary(props){
    const [categories,setCategories] = useState([]),
        [list,setList] = useState([]),
        [total,setTotal] = useState(0),
        [currentCate,setCurrentCate] = useState(''),
        [page,setPage] = useState(1),
        [kw,setKw] = useState(''),
        [loading,setLoading] = useState(false),
        [searching,setSearching] = useState(false),
        sectionRef = useRef();


    useEffect(()=>{
        const language = props.setting.language
        getNavigationLibraryCategories(language).then(data=>{
            if(data){
                setCategories(data)
                getList()
            }
        })
    },[])

    useEffect(()=>{
        getList()
    },[currentCate])

    useEffect(()=>{
        if(kw===''){
            getList()
        }
    },[kw])


    /**
     * 搜索
     */
    const handleSearch = async ()=>{
        if(searching){return}
        setSearching(true)
        getList().then(()=>{
            setSearching(false)
        })
    }

    /**
     * 获取/添加列表
     */
    const getList = async (increment=false)=>{
        if(loading) return
        setLoading(true)
        const newPage = increment?page+1:1
        const result = await getNavigationLibrary({
            type:currentCate,
            kw,
            lang:props.setting.language,
            page:newPage
        }) || ({list:[],total:0})
        if(increment){
            if(result.list.length>0){
                const {list:_list,total} = result
                console.log(result)
                setList([...list,..._list]) // pending
                setTotal(total)
                setPage(newPage)
            }
        }else{
            setList(result.list)
            setTotal(result.total)
        }
        sectionRef.current.scrollTop = 0
        setLoading(false)
    }

    /**
     * 添加导航书签
     */
    const handleAddNavigation = (navigationObj)=>{
        return ()=>{
            if(props.navigation.list.find(item=>item._id===navigationObj._id)){
                message.info('已经存在')
            }else{
                props.incrementNavigation(navigationObj)
            }
        }
    }

    const handleScroll = (e)=>{
        const target = e.target,
            clientHeight = target.clientHeight,
            scrollTop = target.scrollTop,
            scrollHeight = target.scrollHeight

        if(scrollHeight-scrollTop===clientHeight && total>list.length){
            getList(true)
        }
    }

    return <Translation>
        {t=>(
            <Drawer width={350} id='navigation-drawer' visible={true} onClose={props.onClose} closable={false}>
                <CloseOutlined className='close-btn' onClick={props.onClose} />
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('bookmark.library')} key="1">
                        <div className='navigation-library'>
                            <header>
                                <Search
                                    placeholder="网站搜索"
                                    allowClear
                                    enterButton
                                    size="large"
                                    onChange={(e)=>{
                                        setKw(e.target.value)
                                    }}
                                    onSearch={handleSearch}
                                />
                            </header>
                            <main>
                                <ul className='library-nav scrollbar-hidden'>
                                    {
                                        categories.map((category)=>{
                                            return (
                                                <li key={category.value}
                                                    className={`${currentCate===category.value?'active':''}`}
                                                    onClick={()=>{setCurrentCate(category.value);setPage(1)}}
                                                >{category.label}</li>
                                            )
                                        })
                                    }
                                </ul>
                                <section ref={sectionRef} className='library-content' onScroll={handleScroll}>
                                    <ul>
                                        {
                                            list.map(navigationObj=>{
                                                return (
                                                    <li key={navigationObj._id}>
                                                        <img onClick={handleAddNavigation(navigationObj)}
                                                             src={navigationObj.icon} alt={navigationObj.name}/>
                                                        <div onClick={handleAddNavigation(navigationObj)}>
                                                            <span>{navigationObj.name}</span>
                                                            {/* padding*/}
                                                            <span>{navigationObj.keyword}</span>
                                                        </div>
                                                        <a href={navigationObj.url} target='_blank'><LinkOutlined /></a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className='not-more'>
                                        {
                                            loading?
                                                <LoadingOutlined />:
                                                <span>没有更多了</span>
                                        }
                                    </div>
                                </section>
                            </main>
                        </div>
                    </TabPane>
                    <TabPane className='navigation-custom' tab={t('word.custom')} key="2">
                        <Custom/>
                    </TabPane>
                </Tabs>
            </Drawer>
        )}
    </Translation>
}

export default connect(
    (state)=>({
        setting:state.setting,
        navigation:state.navigation
    }),
    {
        incrementNavigation:(data)=>({type:'incrementNavigation',data})
    }
)(NavigationLibrary)