export const getCookie = (key)=>{
    const arrStr = document.cookie.split("; ");
    for (let i = 0; i < arrStr.length; i++) {
        const temp = arrStr[i].split("=");
        if (temp[0] === key) return JSON.parse(decodeURI(temp[1]));  //解码
    }
    return "";
}

export const setCookie = (key,value,options = {})=>{
    let str = ''
    if(value===undefined){
        // 删除cookie
        str += `${key}=${encodeURI(JSON.stringify(getCookie(key)))};expires=${new Date(0)}`
    }else{
        str += `${key}=${encodeURI(JSON.stringify(value))}`;
        if (options.expires) {
            // 设置cookie有效期
            const ms = options.expires * 1000;
            const date = new Date(Date.now() + ms);
            str += ";expires=" + date;
        }
    }
    if(options.domain){
        str += ";domain="+options.domain
    }
    if(options.path){
        str += ";path="+options.path
    }
    document.cookie = str;
}

