import {connect} from 'react-redux'

function LeftBottom(props){
    return null
}

export default connect(
    null,
    {
    }
)(LeftBottom)