import {combineReducers} from 'redux'
import navigation from './navigation'
import setting from './setting'
import search from './search'
import wallpaper from './wallpaper'
import common from './common'
import user from './user'
import system from './system'

export default combineReducers({
    system,
    navigation,
    setting,
    search,
    wallpaper,
    common,
    user
})