import {nanoid} from 'nanoid'
import {getLocalStorage} from "./storage";

/**
 * 事件回调.
 * @callback listenerCallback
 * @param request {Object}
 * @param sender {any}
 * @param response {function}
 */


/**
 *
 * @param type {'install'|'login'|'connect-install'|'connect-login'} 事件类型
 * @param cb {listenerCallback} 回调
 * @param responseData {Object} 成功返回数据
 */
export function addListener(type,cb,responseData=undefined){
    if (window.chrome.runtime.id !== "browser") {
        /** 监听主题安装 */
        window.chrome.runtime.onMessage.addListener( fn);
        function fn(request,sender,response){
            if(request.type===type && request.id===window.chrome.runtime.id) {
                cb(request.data,sender,(data)=>{
                    response({status:200,data:data || responseData})
                })
            }
            // padding bug
            // window.chrome.runtime.onMessage.removeListener(fn)
        }
    }
}

/**
 * 与content连接(接收端)
 * @param type {'install'|'login'} 事件类型
 * @param success 成功回调
 */
export function connectContent(type='install',success=undefined){
    addListener(`connect-${window.chrome.runtime.id}-${type}`,(request,sender,response)=>{
        if(typeof success==='function'){
            success()
            response()
        }
    })
}

/**
 * 获取插件详情
 */
export function getDetail(){
    const system = getLocalStorage('system-data')
    if(system) return system
    if(window.chrome.runtime.id!=='browser') {
        const manifest = window.chrome.runtime.getManifest()
        return {
            cid:nanoid(),
            id:window.chrome.runtime.id,
            name:manifest.name,
            version:manifest.version,
        }
    }else{
        return {id:'browser',cid:nanoid()}
    }
}