const searchEngineName = {
    "baidu":"百度",
    "sougou":'搜狗',
    "bing":"必应"
}
export default {
    ...searchEngineName,
    word:{
        // a
        add:'添加',
        // b
        baidu:'百度',
        bookmark:'书签',
        border:'边框',
        // c
        cancel:'取消',
        close:'关闭',
        custom:'自定义',
        color:'颜色',
        columns:'列',
        confirm:'确认',
        // d
        delete:'删除',
        // e
        edit:'编辑',
        example:'例子',
        error:'错误',
        // f
        feedback:'意见反馈',
        // g
        // h
        height:'高度',
        hidden:'隐藏',
        // i
        icon:'图标',
        // j
        // k
        // l
        language:'语言',
        layout:'布局',
        length:'长度',
        login:'登录',
        link:'链接',
        // m
        more:'更多',
        // n
        name:'名称',
        navigation:'导航',
        next:'下一步',
        // o
        opacity:'透明度',
        other:'其它',
        // p
        param:'参数',
        password:'密码',
        preview:'预览',
        previous:'上一步',
        // q
        // r
        radius:'圆角',
        register:'注册',
        remove:'移除',
        reset:'重置',
        rows:'行',
        // s
        setting:'设置',
        search:'搜索',
        site:'网站',
        size:'大小',
        space:'空白',
        spacing:'间距',
        style:'样式',
        submit:'提交',
        system:'系统',
        // t
        test:'测试',
        theme:'主题',
        time:'时间',
        // u
        url:'网址',
        use:'使用',
        user:'用户',
        username:'用户名',
        using:"使用中",
        upload:'上传',
        // v
        visible:'显示',
        // w
        wallpaper:'壁纸',
        weather:'天气',
        width:'宽度',
        // x
        // y
        // z
    },
    setting:{
        theme1:'主题一',
        theme2:'主题二',
        theme3:'主题三',
        openNewWindowSearch:'新窗口打开搜索结果',
        openNewWindowBookmark:'新窗口打开书签',
        searchEngine:'搜索引擎',
        visibleMoreTheme:'显示更多主题',
        visibleAddButton:'显示添加按钮',
        visibleWallpaperMask:'显示背景遮罩',
        visibleLogo:'显示Logo',
        moreTheme:'更多主题',
        moreWallpaper:'更多壁纸',
        clickVisible:'点击打开',
        bookmarkMinimized:'您在设置中已经关闭导航链接！',
        installTheme:'安装主题',
        favTheme:'收藏主题',
        favWallpaper:'收藏壁纸',
        cancelFav:'取消收藏',
        empty:'清空',
        notEmpty:'不清空',
        giveStar:'五星好评',
        backgroundColor:'背景颜色',
        searchInput:'搜索框',
        navigationLink:'导航链接',
        wallpaperMask:'壁纸遮罩',
        quickLinks:'快速链接'
    },
    bookmark:{
        library:'书签库',
        add:'添加书签',
        modifyNavigationBookmarks:"修改导航书签",
        localUpload:'本地上传',
        match:'在线匹配'
    },
    search:{
        custom:'添加自定义搜索',
        defaultInfo:"自定义的搜索引擎",
        deleteConfirm:'确认要删除%s搜索引擎吗？',
        list:'搜索列表',
    },
    tip:{
        linkRequired:'请输入链接。',
        linkNotQueryWord:"链接没有可替换的词{query}。",
        nameRequired:'请输入名称。',
        usernameRequired:'请输入用户名',
        passwordRequired:'请输入密码',
        confirmPasswordRequired:'请输入确认密码',
        confirmPasswordDifferent:'确认密码与密码不一致',
        linkOrNameRequired:'链接名称不能为空。',
        usernameIsExist:'用户名已存在。',
        passwordLength:'密码必须是6-20位字符。',
        usernameOrPasswordError:'用户名或密码错误。',
        linkUseHelp:'【用{query}替换搜索词】',
        loggedMoreFeatures:"登录后获取更多功能",
        networkError:"网络错误",
        cloudNotRecoveryData:"云端没有可恢复的数据",
        loginExpire:'用户登录已过期，请重新登录!',
        recoverySuccess:"数据恢复成功",
        backupSuccess:"数据备份成功",
        syncSuccess:"数据同步成功",
        cloudAndLocalDataDifferent:"云端数据与本地不一致。",
        mergeDataOption:"请选择数据合并方式。",
        mergeData:"双向同步",
        useCloudData:"云端覆盖本地",
        useLocalData:"本地覆盖云端",
        removeWallpaper:"确认要移除这张壁纸吗？",
        favWallpaperEmpty:"您还没有收藏任何壁纸",
        passwordTypeError:"不能是纯数字或字母"
    },
    wallpaper:{
        getMoreWallpaper:'获取更多壁纸',
        next:'下一张',
        prev:'上一张',
        use:'使用壁纸',
        remove:'移除壁纸',
        upload:'上传壁纸',
        localUpload: "本地上传",
        themeWallpaper:'主题壁纸',
        favorite:'我的收藏'
    },
    user:{
        autoBackup:'自动备份',
        backupCycle:'备份周期',
        confirmPassword:'确认密码',
        everyTime:'每次',
        everyDay:'每天',
        everyWeek:'每周',
        recovery:'数据恢复',
        manualBackup:'手动备份',
        signOut:'退出',
        loginOut:'退出登录',
        userLogin:'用户登录',
        userRegister:'用户注册',
        accountRegister:'账号注册',
        accountLogin:'账号登录',
        quickLogin:'快捷登录',
        qqQuickLogin:'QQ快捷登录',
        clearData:"清空本地数据"
    }
}