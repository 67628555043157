import {getDefaultNavigation as getDefault} from '../../api'
import {GET_DEFAULT_NAVIGATION, INCREMENT_NAVIGATION} from '../constants'

export const getDefaultNavigationAsync = function(){
    return async (dispatch)=>{
        const data = await getDefault()
        dispatch({type:GET_DEFAULT_NAVIGATION,data})
    }
}

export const incrementNavigationAsync = function(data){
    return async (dispatch)=>{
        dispatch({type:INCREMENT_NAVIGATION,data})
    }
}