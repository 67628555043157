import {connect} from "react-redux";
import {Tooltip, Drawer} from "antd";
import {useState} from "react";
import {CloseOutlined, createFromIconfontCN, SettingOutlined} from "@ant-design/icons";
import {Translation} from "react-i18next";
import WallpaperList from "../Components/WallpaperList";
import Weather from "../Components/weather";

const IconFont = createFromIconfontCN({
    scriptUrl:[
        '/js/iconfont.js'
    ]
})

function LeftTop(props){
    const [showWallpaperList,setShowWallpaperList] = useState(false)

    const handleShowSetting = ()=>{
        props.changeSettingDrawer(true)
    }
    const handleShowWallpaperList = ()=>{
        setShowWallpaperList(true)
    }

    return (
        <Translation>
            {t=>(
                <>
                    <div id='right-top'>
                        <div id='wallpaper-btn' onClick={handleShowWallpaperList}>
                            <Tooltip placement="bottom" title={t('word.wallpaper')}>
                                <IconFont type='pick-app-menu' />
                            </Tooltip>
                        </div>
                        <div id='setting-btn' onClick={handleShowSetting}>
                            <Tooltip placement="bottom" title={t('word.setting')}>
                                <SettingOutlined/>
                            </Tooltip>
                        </div>
                    </div>

                    {
                        showWallpaperList?
                            <Drawer width={350} mask={true} zIndex={1002} placement='right' id='setting' visible={true}
                                    onClose={()=>{setShowWallpaperList(false)}} closable={false}>
                                <CloseOutlined className='close-btn' onClick={()=>{setShowWallpaperList(false)}} />
                                <WallpaperList />
                            </Drawer>:
                            null
                    }
                </>
            )}
        </Translation>
    )
}

export default connect(
    (state)=>({
        visible:state.setting.visible.weather
    }),
    {
        changeSettingDrawer:data=>({type:'changeSettingDrawer',data}),
    }
)(LeftTop)