import {message} from "antd";

export default (kw)=>{
    if(/^\s*help\s*?$/.test(kw)){
        // 查看帮助
        message.info('开发中')
        return
    } if(/^install\s\w/.test(kw)){
        // 主题安装
        const url = kw.replace(/^install\s/,'')
        try{
            const wallpaperIframe = document.querySelector('Iframe.wallpaper')
            if(wallpaperIframe){
                // pending 错误监听
                wallpaperIframe.setAttribute('src',url)
            }
        }catch (e){
            message.warning('主题安装失败,请检测链接是否可用!')
        }
        return
    }else if(/^dev\s\w/.test(kw)){
        const dev = kw.slice(4)
        switch (dev){
            case('pick'):
                return message.info('test')
        }
    }
    return kw
}