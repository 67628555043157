import {getDetail, getLocalStorage, setLocalStorage} from "../../utils";
import {stats} from '../../api'

const STORAGE_KEY = 'system-data'

function getStorage(){
    let storage = getLocalStorage(STORAGE_KEY)
    if(!storage){
        storage = getDetail()
        if(storage.id !== 'browser'){
            stats('install',storage)
        }
        setLocalStorage(STORAGE_KEY,storage)
    }
    return storage
}


export default (preState=getStorage(),action)=>{
    switch (action.type){
        default:
            return preState
    }
}