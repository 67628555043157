import i18n from "i18next";
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './en'
import zh from './zh'
import store from "../redux/store";

const lng = store.getState().setting.language

const resources = {
    zh:{
        translation: zh
    },
    en:{
        translation: en
    }
}

i18n.use(LanguageDetector).use(initReactI18next).init({
    fallbackLng:lng,
    lng,
    debug:false,
    resources,
    interpolation:{
        escapeValue:false
    }
})

export default i18n