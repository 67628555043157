export const initState = {
    visibleSetting:false
}

export default (preState=initState,action)=>{
    const storage = {...initState}
    switch (action.type){
        case 'changeSettingDrawer':
            storage.visibleSetting = action.data
            return storage
        default:
            return preState
    }
}