/**
 * 获取本地storage储存
 * @param key 储存的键名
 * @returns {any}
 */
export const getLocalStorage = (key)=>{
    return JSON.parse(localStorage.getItem(key) || 'null')
}

/**
 * 设置本地storage储存
 * @param key 要储存的数据的键
 * @param storage 要储存的数据
 */
export const setLocalStorage = (key,storage)=>{
    if(storage===undefined){
        localStorage.removeItem(key)
    }else{
        localStorage.setItem(key,JSON.stringify(storage))
    }
}