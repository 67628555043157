export const urlParse = (url)=>{
    url += ' '
    let urlObj = {
        protocol: /^(.+):\/\//,
        host: /:\/\/(.+?)[?#\s/]/,
        path: /\w(\/.*?)[?#\s]/,
        query: /\?(.+?)[#/\s]/,
        hash: /#(\w+)\s$/

    }

    function formatQuery(str) {
        return str.split('&').reduce((a, b) => {
            let arr = b.split('=')
            a[arr[0]] = arr[1]
            return a
        }, {})
    }

    for(let key in urlObj) {
        let pattern = urlObj[key]
        urlObj[key] = key === 'query' ? (pattern.exec(url) && formatQuery(pattern.exec(url)[1])) : (pattern.exec(url) && pattern.exec(url)[1])
    }

    return urlObj
}

/**
 * 将类似a=1&b=2&c=3字符串解析成Object
 * @param str
 * @returns {{}}
 */
export function parseString(str){
    if(!str)return {}
    const resObj = {}
    str.split('&').map(item=>item.split('=')).forEach((item)=>{
        if(typeof item[1] === 'string' && /^\d+$/.test(item[1])){
            resObj[item[0]]  = parseInt(item[1])
        }else{
            resObj[item[0]]  = item[1]
        }
    })
    return resObj
}