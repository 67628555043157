import React, {useState,useMemo} from 'react'
import {Button, Switch,Space} from 'antd'
import {Slider} from '../../components'
import {DownOutlined} from "@ant-design/icons";
import {connect} from 'react-redux'
import {Translation} from 'react-i18next'

function Navigation(props){
    const [activeStyle,setActiveStyle] = useState(true),
        [activeLayout,setActiveLayout] = useState(false),
        [activeOther,setActiveOther] = useState(false),
        navigation = props.navigation,
        maxCols = useMemo(()=>{
            const size = props.navigation.size*5*0.01,
                spaceX = props.navigation.spaceX*0.01*size
            return Math.floor((spaceX+100)/(size+spaceX))
        },[props.navigation.size,props.navigation.spaceX]),
        visible = props.setting.visible.bookmark

    const handleChange = (name)=>{
        return (e)=>{
            changeNavigation(name,e.target.value*1)
        }
    }

    const handleReset = ()=>{
        props.resetNavigation()
    }

    const changeNavigation = (key, value)=>{
        const navigation = {...props.navigation}
        if(key==='size' || key==='space' ){
            if(props.navigation['columns']>maxCols){
                navigation['columns'] = maxCols
            }
        }
        navigation[key] = value
        props.changeNavigation(navigation)
    }

    return <Translation>
        {t=>(
            <div id='setting-navigation'>
                {
                    visible?
                        <>
                            <div className={`style card ${activeStyle?'':'mini'}`}>
                                <header onClick={()=>{setActiveStyle(!activeStyle)}}>
                                    {t('word.style')}<DownOutlined />
                                </header>
                                {
                                    activeStyle?
                                    <main>
                                        <div className='slider'>
                                            <span>{t('word.height')}</span>
                                            <Slider min={0}
                                                    max={500}
                                                    value={navigation.top}
                                                    onChange={handleChange('top')}/>
                                            <span>{navigation.top}px</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.size')}</span>
                                            <Slider min={50}
                                                    max={200}
                                                    value={navigation.size}
                                                    onChange={handleChange('size')}/>
                                            <span>{navigation.size}%</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.rows')}{t('word.spacing')}</span>
                                            <Slider min={0}
                                                    max={200}
                                                    value={navigation.spaceY}
                                                    onChange={handleChange('spaceY')}/>
                                            <span>{navigation.spaceY}%</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.columns')}{t('word.spacing')}</span>
                                            <Slider min={0}
                                                    max={200}
                                                    value={navigation.spaceX}
                                                    onChange={handleChange('spaceX')}/>
                                            <span>{navigation.spaceX}%</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.radius')}</span>
                                            <Slider min={0}
                                                    max={50}
                                                    step={1}
                                                    value={navigation.radius}
                                                    onChange={handleChange('radius')}/>
                                            <span>{navigation.radius}%</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.opacity')}</span>
                                            <Slider min={0.2}
                                                    max={1}
                                                    step={0.01}
                                                    value={navigation.opacity}
                                                    onChange={handleChange('opacity')}/>
                                            <span>{navigation.opacity}</span>
                                        </div>
                                    </main>:null
                                }
                            </div>

                            <div className={`layout card ${activeLayout?'':'mini'}`}>
                                <header onClick={()=>{setActiveLayout(!activeLayout)}}>{t('word.layout')}<DownOutlined /></header>
                                {
                                    activeLayout?
                                    <main>
                                        <div className='slider'>
                                            <span>{t('word.columns')}</span>
                                            <Slider min={1}
                                                    max={maxCols}
                                                    value={navigation.columns}
                                                    onChange={handleChange('columns')}/>
                                            <span>{navigation.columns}{t('word.columns')}</span>
                                        </div>
                                        <div className='slider'>
                                            <span>{t('word.rows')}</span>
                                            <Slider min={1}
                                                    max={5}
                                                    value={navigation.rows}
                                                    onChange={handleChange('rows')}/>
                                            <span>{navigation.rows}{t('word.rows')}</span>
                                        </div>
                                    </main>:null
                                }
                            </div>

                            <div className={`other card ${activeOther?'':'mini'}`}>
                                <header onClick={()=>{setActiveOther(!activeOther)}}>{t('word.other')}<DownOutlined /></header>
                                {
                                    activeOther?
                                    <main>
                                        <div className='switch'>
                                            <Switch defaultChecked={navigation.visibleAddButton}
                                                    onChange={(val) => {
                                                        changeNavigation('visibleAddButton', val)
                                                    }}/>{t('setting.visibleAddButton')}
                                        </div>

                                        <div className='switch'>
                                            <Switch defaultChecked={navigation['blank'] !== false}
                                                    onChange={(val) => {
                                                        changeNavigation('blank', val)
                                                    }}/>{t('setting.openNewWindowBookmark')}
                                        </div>


                                    </main>:null
                                }
                            </div>

                            <div style={{margin:'1rem'}}>
                                <Button className='simple-btn' type='primary'
                                        onClick={handleReset}>{t('word.reset')}
                                </Button>
                            </div>
                        </>
                    :
                    <div style={{ margin:'1rem' }}>
                        <Space>
                            {t('setting.bookmarkMinimized')}
                            <Button className='show-btn' onClick={()=>{
                                const setting = {...props.setting}
                                setting.visible.bookmark = true
                                props.changeSetting(setting)
                            }}>{t('setting.clickVisible')}</Button>
                        </Space>
                    </div>

                }
            </div>
        )}
    </Translation>
}

export default connect(
    (state)=>({
        navigation:state.navigation,
        setting:state.setting
    }),{
        changeNavigation:data=>({type:'changeNavigation',data}),
        changeSetting:data=>({type:'changeSetting',data}),
        resetNavigation:()=>({type:'resetNavigationSetting'})
    }
)(Navigation)
