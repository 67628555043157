import {useRef, useState} from 'react'
import {connect} from "react-redux";
import {message, Tooltip, Popconfirm} from 'antd';
import {PictureOutlined, HeartFilled, HeartOutlined, PlusOutlined, CloseOutlined} from '@ant-design/icons'
import {Translation} from 'react-i18next'
import {imageResize, urlParse} from '../../../utils'
import {favWallpaper, uploadFile, deleteFile} from "../../../api";

function WallpaperList(props){

    const fileRef = useRef()

    const [activeLink,setActiveLink] = useState(0);

    const handleChangeWallpaper = (wallpaper,isFavWallpaper=false,isLocalWallpaper=false)=>{
        return ()=>{
            props.changeWallpaper({...props.wallpaper,wallpaper,isFavWallpaper,isLocalWallpaper})
        }
    }

    const handleGetMore = () => {
        window.open('https://www.picknewtab.com','_blank')
    }

    const toggleFav = (wallpaper)=>{
        return ()=>{
            favWallpaper(wallpaper).then(res=>{
                if(res.code===10000){
                    props.changeUser({...props.user,favWallpapers:res.data})
                }else{
                    message.info(res.message || '网络错误')
                }
            })
        }
    }

    const handleActiveLink = (active)=>{
        return ()=>{
            if(active===0){
            }else if(active===1){

            }
            setActiveLink(active)
        }
    }

    /**
     * 壁纸上传监听
     */
    const onLocalUpload = ()=>{
        fileRef.current.click()
    }

    /**
     * 本地壁纸上传
     */
    const handleLocalUpload = (e)=>{
        const files =  e.target.files
        uploadFile(files).then((data)=>{
            const err = []
            const success = []
            for(let url of data){
                if(typeof url === 'string'){
                    success.push(url)
                }else{
                    err.push(url)
                }
            }
            props.increaseLocalWallpaper(success)
            if(err.length !== 0){
                console.log(err)
            }
            document.querySelector('#file-upload').reset()
        }).catch(()=>{
            document.querySelector('#file-upload').reset()
        })
    }

    const handleRemoveWallpaper = (url)=>{
        if(!props.wallpaper.wallpapers || props.wallpaper.wallpapers.length<=1){
            return
        }
        props.removeWallpaper(url)
    }

    const handleDeleteLocalWallpaper = (url)=>{
        const store = {...props.wallpaper}
        const path = urlParse(url)
        const reg = new RegExp(`${path.path.substring(1)}`)
        const index = store.localWallpapers.findIndex(item=>reg.test(item))
        deleteFile(url).then(null)
        store.localWallpapers.splice(index,1)
        if(reg.test(store.wallpaper)){
            store.wallpaper = store.wallpapers[0]
        }
        props.changeWallpaper(store)
    }


    return <Translation>
        {
            t=>(
                <div id='wallpaper-list'>
                    {(()=>{
                        if(activeLink===0){
                            return <ul className='theme-list'>
                                {
                                    props.wallpaper.wallpapers && props.wallpaper.wallpapers.map(wallpaper=>(
                                        <li key={wallpaper}>
                                            <img onClick={handleChangeWallpaper(wallpaper)} src={wallpaper+imageResize(350,180)} alt="img"/>
                                            <button className="btn" onClick={handleChangeWallpaper(wallpaper)}>
                                                {t('wallpaper.use')}
                                            </button>
                                            {
                                                props.wallpaper.wallpapers.length>1?
                                                <Popconfirm
                                                    title={t('tip.removeWallpaper')}
                                                    onConfirm={()=>{
                                                        handleRemoveWallpaper(wallpaper)
                                                    }}
                                                    okText={t('word.confirm')}
                                                    cancelText={t('word.cancel')}
                                                >
                                                    <Tooltip title={t('wallpaper.remove')} placement='left'>
                                                        <CloseOutlined />
                                                    </Tooltip>
                                                </Popconfirm>:null
                                            }
                                        </li>
                                    ))
                                }
                                <a href="https://www.jianyueku.com" target="_blank">
                                    <li className='more-btn'>
                                        <PictureOutlined />
                                        {t('wallpaper.getMoreWallpaper')}
                                    </li>
                                </a>
                            </ul>

                        }else if(activeLink===1){
                            return <ul className='fav-list'>
                                {
                                    props.favs && props.favs.length>0?
                                        props.favs.map(wallpaper=>(
                                            <li key={wallpaper}>
                                                <img onClick={handleChangeWallpaper(wallpaper,true)} src={wallpaper+imageResize(350,180)} alt="img"/>
                                                <button className="btn" onClick={handleChangeWallpaper(wallpaper,true)}>
                                                    {t('wallpaper.use')}
                                                </button>
                                                {
                                                    props.favs && props.favs.includes(wallpaper)?
                                                        <Tooltip title={t('setting.cancelFav')} placement='left'>
                                                            <HeartFilled className='fill' onClick={toggleFav(wallpaper)} />
                                                        </Tooltip>:
                                                        <Tooltip title={t('setting.favWallpaper')} placement='left'>
                                                            <HeartOutlined onClick={toggleFav(wallpaper)} />
                                                        </Tooltip>
                                                }
                                            </li>
                                        )):
                                        <div style={{marginTop:'1rem',color:'#888'}}>{t('tip.favWallpaperEmpty')}</div>
                                }
                            </ul>

                        }else if(activeLink===2){
                            return <ul className='local-upload'>
                                {
                                    props.wallpaper.localWallpapers && props.wallpaper.localWallpapers.map(wallpaper=>{
                                        return <li key={wallpaper}>
                                            <img
                                                onClick={handleChangeWallpaper(wallpaper,false,true)}
                                                src={wallpaper+imageResize(350,180)} alt="img"
                                            />
                                            <button
                                                className="btn"
                                                onClick={handleChangeWallpaper(wallpaper,false,true)}
                                            >
                                                {t('wallpaper.use')}
                                            </button>
                                            <Popconfirm
                                                title={t('tip.removeWallpaper')}
                                                onConfirm={()=>{
                                                    handleDeleteLocalWallpaper(wallpaper)
                                                }}
                                                okText={t('word.confirm')}
                                                cancelText={t('word.cancel')}
                                            >
                                                <Tooltip title={t('wallpaper.remove')} placement='left'>
                                                    <CloseOutlined />
                                                </Tooltip>
                                            </Popconfirm>
                                        </li>
                                    })
                                }
                                <li className='more-btn' onClick={onLocalUpload}>
                                    <PlusOutlined />
                                    {t('wallpaper.upload')}
                                </li>
                            </ul>
                        }else{
                            return null
                        }
                    })()}

                    {
                        <form id='file-upload'>
                            <input onChange={handleLocalUpload}
                                   ref={fileRef}
                                   type="file"
                                   style={{display:"none"}}
                                   multiple
                                   accept='image/*'
                            />
                        </form>
                    }
                </div>
            )
        }
    </Translation>

}

export default connect(
    (state)=>({
        wallpaper:state.wallpaper,
        user:state.user,
        favs:state.user.favWallpapers
    }),
    {
        changeUser:(data)=>({type:'changeUser',data}),
        changeWallpaper:(data)=>({type:'changeWallpaperStorage',data}),
        toggleFavWallpaper:(data)=>({type:'toggleFavWallpaper',data}),
        increaseLocalWallpaper:(data)=>({type:"increaseLocalWallpaper",data}),
        removeWallpaper:(data)=>({type:"removeWallpaper",data})
    }
)(WallpaperList)